// TODO: kill the copy-paste at client/src/components/Analytics/types.ts
// import { UserFromApi } from '@components/Project/DataBank/types';
import {
  Action,
  AutomationStepType,
  BulkTaskUpdateAction,
  DynamicEntity,
  EntityType,
  EventTypes,
  InputType,
  ProjectViewType,
  RequiredForDynamic,
  StepFieldKeys,
  Trigger,
  UserRole
} from '@enums';

import {
  Group as GroupBase,
  GroupProp as TargetGroupBase,
  ProjectDetail as ProjectDetailForReexport,
  Search as ProjectViewSearch
} from '@components/Project/ProjectView/types';
import { Filter as TaskFilterBase } from '@components/Calendar/types';
import { Company as CompanyBase } from '@state/reducers/authentication/types';
import { SelectedProjectProp } from '@components/Project/redux/reducer/types';
import {
  CommentType,
  Contact,
  Dashboard,
  FeedsConnection,
  File,
  FormLayout,
  IntegrationProvider,
  PrivelegedTaskConnection,
  ProjectColumnVisibility,
  ProjectProperty,
  ProjectRequestStatus,
  ProjectStatus,
  Team as TeamGeneratedType
} from '@generated/types/graphql';
import { PropertyValue } from '@utils/properties';

export enum RecordType {
  ACCOUNT = 'ACCOUNT',
  PROJECT = 'PROJECT',
  DEAL = 'DEAL'
}

export type ProjectDetail = ProjectDetailForReexport & {
  type: RecordType;
};
export type UserRoles = UserRole;
export type Company = CompanyBase;
export type TaskFilter = TaskFilterBase;
export type Group = GroupBase;
export type TargetGroup = TargetGroupBase;

export interface Search extends ProjectViewSearch {}

export interface Pager {
  skip: number;
  limit: number;
  page: number;
  name?: string;
}

export type Role = {
  id: number;
  name: string;
};

export type ProjectId = number;

export interface ProjectAdapted {
  projectId: ProjectId;
  projectProgress: number;
  projectDetail: ProjectFromProjectListApi & {
    imgSrc: ProjectFromProjectListApi['imageUrl'];
    ownerEmail: ProjectFromProjectListApi['homeOwnerEmail'];
    plannedWorkOrders: PrivelegedTaskConnection;
    inProgressWorkOrders: PrivelegedTaskConnection;
    referrerContact: Contact;
    pinnedComments: FeedsConnection;
  };
  membersList: {
    id: number;
    avatarUrl: string;
    name: string;
    memberType: string;
    email: string;
  }[];
}

export type ProjectFromProjectListApi = WithPrivileges & {
  id: number;
  title: string;
  description: string;
  address: string;
  geoLocation: string;
  createdAt: string;
  progress: 0 | 1; // ?
  homeOwnerEmail: string;
  imageUrl: string;
  isActive: boolean;
  status: ProjectStatus;
  isFavorite?: boolean;
  isCompleted: boolean;
  isOverdue: boolean;
  dealValue?: number;
  dealSize?: number;
  projectValue?: number;
  projectSize?: number;
  confidence?: number;
  companyId: number;
  ahj: string;
  phone: string;
  updatedAt: string;
  additional: { [key in string]: string | number | { id: number | string } | UserFromApi | string[] };
  streetViewUrl: null | string;
  members: MemberFromProjectListApi[];
  overdueBy: number | null;
  tasks: {
    total: number;
    completed: number;
  };
  createdById: number;
  stageId: number;
  stage: WorkspaceStageFromAPI;
  blueprintId?: number;
  timeline?: (WorkspaceStageFromAPI & {
    isFinished: boolean;
    spentTimeInMilliseconds: number;
    isStarted: boolean;
  })[];
  tasksInStage: number;
  lastActivity: string;
  type: RecordType;
  ownerId?: number;
  contacts?: { contact: Contact }[];
  trades: ProjectTrade[];
  accountStatus?: AccountStatus;
  accountType?: AccountType;
  parentProjectId?: number;
  jurisdictionId?: number;
  requestStatus?: ProjectRequestStatus;
};

export interface MemberFromProjectListApi {
  id: number;
  memberType: UserRoles;
  member: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    avatarUrl: string;
  };
  team: {};
}

export interface Feed<TPayload = Project | Team | Form | Task> {
  id?: number;
  type?: string;
  createdAtWord?: string;
  event: EntityType;
  eventType: EventTypes;
  createdAt?: string;
  read?: boolean;
  userId?: number;
  companyId?: number;
  userName?: string;
  userImage?: string;
  payload: TPayload;
  selfActivity?: boolean;
}

export type PayloadEmail = {
  companyId: number;
  id: number;
  emailMessage: EmailMessage;
  project: Project;
};

export interface ChangeOrderNotificationPayload {
  added: {
    id: number;
    name: string;
    quantity: number;
    description: string;
    unitCost: number;
    unitPrice: number;
  }[];
  modified: {
    id: number;
    name: string;
    modifiedProperties: {
      property: 'quantity' | 'unitPrice' | 'unitCost' | 'description' | string;
      oldValue: number | string;
      newValue: number | string;
    }[];
  }[];
  removed: {
    id: number;
    name: string;
    quantity: number;
    description: string;
    unitCost: number;
    unitPrice: number;
  }[];
  newTotalCost: number;
  newTotalPrice: number;
  newProfit: number;
  newMargin: number;
  oldTotalCost: number;
  oldTotalPrice: number;
  oldProfit: number;
  oldMargin: number;
  isTotalCostChanged: boolean;
  isTotalPriceChanged: boolean;
  isProfitChanged: boolean;
  isMarginChanged: boolean;
  project: Project;
  // starts from 0
  revision: number;
}

export interface Form {
  id: number;
  name: string;
  labels: Label[];
  companyId: number;
  assignees: Member[];
  project: {
    id: number;
    title: string;
  };
  members: UserFromApi[];
  fields: FieldJson[];
}

export interface Project {
  id: number;
  name: string;
  companyId: number;
  labels: Label[];
  members: Member[];
  project: {
    id: number;
    title: string;
    type: RecordType;
  };
  title: string;
  address: string[];
  accountType: AccountType;
  owner?: UserFromApi;
  dealValue?: number;
  type: RecordType;
}

interface Stage {
  id: number;
  name: string;
  yellowSla?: number;
  redSla?: number;
}

export interface Task {
  id: number;
  name: string;
  companyId: number;
  startDate: string;
  endDate: string;
  dueDate: string;
  priority: number;
  dueDateCheckTime?: number;
  previousStage: Stage;
  currentStage: Stage;
  assignees: Member[];
  labels: Label[];
  project: {
    id: number;
    title: string;
    type: RecordType;
  };
  projectId: number;
  stageId: number;
  title: string;
  description: string;
  position: number;
  subTasks: Subtask[];
  subTaskProgress: { completed: number; total: number };
  likesCount: number;
  createdAt: Date;
  modifiedAt: Date;
  isArchived: boolean;
  isCompleted: boolean;
  stage: { position: number };
  completionDate: string;
  googleEventId: string;
  createdBy: number;
  forms: Form[];
  isColored: boolean;
  projectStage?: ProjectStage;
  templateTaskId?: number;
  contacts?: Contact[];
  isField: boolean;
}

export interface TaskTemplate
  extends Omit<Task, 'stageId' | 'projectId' | 'assignees' | 'name' | 'startDate' | 'endDate'> {
  templateUsers: User[];
  templateProperties: Property[];
  stageId: number | null;
  projectId: undefined;
  isTemplate: true;
  startDate?: null;
  endDate?: null;
}

export interface TaskDTO extends Omit<Task, 'labels' | 'assignees'> {
  labels: number[];
  assignees: number[];
}

export interface TaskTemplateDTO extends Omit<TaskTemplate, 'templateUsers' | 'labels'> {
  templateUsers: number[];
  labels: number[];
}

export type Subtask = {
  id?: number;
  detail?: string;
  isCompleted?: boolean;
  companyId?: number;
  createdAt?: Date;
  updatedAt?: Date;
};

export interface Team {
  id: number;
  name: string;
  companyId: number;
  members: Member[];
}

export interface Member {
  id: number;
  name: string;
  avatarUrl: string;
  memberType: string;
}

export interface Label {
  id: number;
  label?: string;
  labelName?: string;
  color: string;
}

export enum ProjectStageType {
  initiation = 'INITIATION',
  new = 'NEW',
  selling = 'SELLING',
  won = 'WON',
  production = 'PRODUCTION',
  complete = 'COMPLETE',
  lost = 'LOST'
}

export interface ProjectStage {
  id: number;
  name: string;
  type: ProjectStageType;
  isEditable?: boolean;
  projectStage: ProjectStageType;
  scope: RecordType.PROJECT | RecordType.DEAL;
}

export enum PropertyType {
  Dropdown = 'DROPDOWN',
  Numeric = 'NUMERIC',
  Date = 'DATE',
  DateTime = 'DATETIME',
  Text = 'TEXT',
  Person = 'PERSON',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  File = 'FILE',
  Link = 'LINK'
}

export enum ExtraFormPropertyType {
  PROJECT = 'PROJECT',
  SEPARATOR = 'SEPARATOR'
}

export type FormPropertyType = PropertyType & ExtraFormPropertyType;

export interface InvoiceItem {
  amount: number;
  description: string;
  quantity: number;
}

export enum SubscriptionStatus {
  active = 'active',
  past_due = 'past_due',
  unpaid = 'unpaid',
  canceled = 'canceled',
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  trialing = 'trialing'
}

export interface Column {
  id: number | string;
  name: string;
  position: number;
  type: PropertyType; // same here
  companyId?: number;
  isRequired?: boolean;
  isAdditional: boolean;
  mappedName: keyof (SelectedProjectProp & {
    taskTitle: string;
    task_in_stage: { taskId: number; stageId: number };
    projectStageUpdatesConnectionMaxCreatedAt: string;
  });
  objectName?: string;
  additional?: ColumnAdditional;
  scope?: RecordType[];
  isReadOnly?: boolean;
  readonly?: boolean;
  multiple?: boolean;
  virtual: boolean;
  virtualType: VirtualPropertyType;
  access: ColumnAccess[];
  shared: boolean;
  sharingType: PropertySharingType;
  isNewRecord: boolean;
  iconUrl: string;
  displayValuePath?: string;
  externalVisibility: ProjectColumnVisibility;
  internalVisibility: ProjectColumnVisibility;
}

export type Property = Omit<Column, 'type' | 'id'> & {
  type: PropertyType;
  id: number | AggregateColumn;
  reversedOrder?: boolean;
};

export interface ColumnAdditional {
  values?: string[];
  defaultValue?: PropertyValue;
  linkLabel?: string;
}

export interface CustomAdditional {
  type: PropertyType;
  isHidden: boolean; // only for editing, we want to preserver values of deleted columns and not override them
  id: number;
  name: string;
  options: string[]; // empty array preserver for future use on other than dropdown types
  value: PropertyValue;
  multiple?: boolean;
}

export interface Paging<PaginationEntity> {
  results: PaginationEntity[];
  total: number;
}

export interface PaginationResult<PaginationEntity> extends Paging<PaginationEntity> {
  page: number;
  nextPage?: number;
  previousPage?: number;
  results: PaginationEntity[];
}

export interface ProjectCommentFromApi {
  comment: string;
  companyId: number;
  id: number;
  createdAt: string;
  modifiedAt: string;
  projectId: ProjectId;
}

export interface SeparatedList<SeparationEntity> {
  separator: string;
  list: SeparationEntity[];
}

export interface User {
  id: number;
  name: string;
  avatarUrl: string;
  memberType?: string;
  email: string;
}

export interface UserFromApi extends User {
  firstName: string;
  lastName: string;
  phone?: string | null;
}

export interface FieldJson {
  key: StepFieldKeys;
  value: string | number | string[] | number[];
}

export interface StepFromApi {
  id: number;
  type: AutomationStepType;
  name: string;
  key: Action | Trigger;
  fields: FieldJson[];
  parentId: number | null;
  workflowId: number;
}

export interface ActionCheckResultFromApi {
  status: number;
  data: any;
  error?: string;
}

export interface AutomationFromApi {
  id: number;
  name: string;
  projectId: ProjectId;
  steps: StepFromApi[];
  isActive: boolean;
  createdBy: UserFromApi;
  companyId: number;
}

export interface DynamicDropdownFilter {
  entity: DynamicEntity;
  keys: KeyType[];
}

export interface KeyType {
  key: RequiredForDynamic;
  value: string;
}

export interface DropdownValueFromApi {
  key: number;
  value: string;
}

export interface WorkflowStep {
  id: number;
  name: string;
  description: string;
  fields: AutomationField[];
}

export interface ActionStep extends WorkflowStep {
  key: Action;
  allowedTriggers?: Trigger[];
}

export interface TriggerStep extends WorkflowStep {
  key: Trigger;
}

export interface ActionsTriggersFromApi {
  actions: ActionStep[];
  triggers: TriggerStep[];
}

export interface AutomationField {
  key: StepFieldKeys;
  type: InputType;
  label?: string;
  required: boolean;
  allowMultiple?: boolean;
  options?: {
    key: number | string;
    value: string;
  }[];
  dynamic?: {
    requiredParams: RequiredForDynamic[];
    entity: DynamicEntity;
  };
  description?: string;
  validationType?: ValidationInputType;
}

export enum ValidationInputType {
  EMAIL = 'email',
  STRING = 'string',
  PHONE = 'phone',
  BOOLEAN = 'boolean',
  URL = 'url'
}

export enum ViewFilterScope {
  WORKSPACE = 'WORKSPACE',
  PERSONAL = 'PERSONAL',
  SHARED = 'SHARED'
}

enum ViewFilterTypeBase {
  TASK = 'TASK',
  ALL = 'ALL' // no filters
}

export enum TaskViewFilterSubType {
  SCHEDULER = 'SCHEDULER',
  DISPATCHER = 'DISPATCHER',
  LIST = 'LIST'
}

export enum CalendarViewType {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day'
}

export const SmartViewFilterType = { ...RecordType, ...ViewFilterTypeBase };
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SmartViewFilterType = RecordType | ViewFilterTypeBase;

export type SmartViewFilter = {
  id: number;
  name: string;
  type: SmartViewFilterType;
  emoji: string;
  filterFolderId: number | null;
  company: CompanyBase;
  conditions: SmartViewCondition;
  isDefault?: boolean;
  companyId: number;
  scope: ViewFilterScope;
  sharedRoles: { id: number; name: string }[];
  sharedTeams: { id: number; name: string }[];
  sharedUsers: { id: number; firstName: string; lastName: string; avatarUrl: string }[];
};

export interface SmartViewCondition {
  properties: {
    /** @deprecated exists only for reference, do not use */
    hiddenColumns?: string[];
    shownColumns: Property['id'][];
    columnsOrder: Partial<Record<Property['id'], number>>;
  };
  search: ProjectViewSearch;
  filter: TaskFilter;
  viewType?: ProjectViewType;
  recordType: RecordType;
}

export enum MemberType {
  WORKER = 'WORKER',
  PROJECT_MANAGER = 'PROJECT_MANAGER'
}

// FIXME: rename to task stage
export interface ProjectStageFromAPI extends Stage {
  position: number;
  createdAt: string;
  updatedAt: string;
  companyId: number;
  newStageId?: number;
  isFinal: boolean;
  isActive: boolean;
  isEditable: boolean;
}

export interface ProjectBulkInsertResponse {
  failedProjects: {
    index: number;
    error: string;
  }[];
}

export type FilterUnseenCount = { id: number; count: number };

// FIXME: rename to project stage
export interface WorkspaceStageFromAPI extends Stage {
  type: ProjectStageType;
  isEditable: boolean;
  scope: RecordType.PROJECT | RecordType.DEAL;
}

// FIXME: rename to task stage
export interface WorkspaceStageDTO {
  name: string;
  type: ProjectStageType;
  scope: RecordType.PROJECT | RecordType.DEAL;
  yellowSla?: number;
  redSla?: number;
}

export interface BlueprintDTO {
  id: number;
  name: string;
  type: RecordType.PROJECT | RecordType.DEAL;
  projectStages: BlueprintProjectStageDTO[];
  slices: BlueprintTimesliceDTO[];
  companyId: number;
}

export interface BlueprintProjectStageDTO {
  id: number;
  projectStageId: number;
  projectStage: WorkspaceStageFromAPI;
  blueprintTasks: BlueprintTaskDTO[];
  position: number;
}

export type CreateBlueprintProjectStageDTO = Pick<BlueprintProjectStageDTO, 'projectStageId'> & {
  afterStageId?: number;
};

export interface BlueprintTimesliceDTO {
  id: number;
  blueprintId: number;
}

export interface BlueprintTaskDTO {
  id: number;
  blueprintId: number;
  blueprintProjectStageId: number;
  taskId: number;
  task: Task;
  sliceId: number;
  createdAt: string;
}

export type CreateBlueprintTaskDTO = { taskId: number; stageId: number; sliceId?: number };

export enum AggregateColumn {
  TASK_WITH_TITLE = 'task_with_title',
  TIME_IN_STAGE = 'time_in_stage'
}

export interface CompanyUser {
  id: number;
  userId: number;
}

export interface RoleDTO {
  id: number;
  companyId: number;
  name: string;
  settings: RoleSettingsDTO;
  active: boolean;
  createdBy: UserFromApi;
  createdAt: string;
  companyUsers: CompanyUser[];
}

export type RoleSettingsDTO = {
  project?: SubjectSettingsDTO;
  task?: SubjectSettingsDTO;
  form?: SubjectSettingsDTO;
  file?: SubjectSettingsDTO;
  team?: TeamSettingsDTO;
  workspace?: WorkspaceSettingsDTO;
};

export type SubjectSettingsDTO = {
  own?: Privilege;
  team?: Privilege;
  all?: Privilege;
};

export type TeamSettingsDTO = Omit<SubjectSettingsDTO, 'team'>;

export type WorkspaceSettingsDTO = {
  settings?: Privilege;
  analytics?: Privilege;
  invite?: Privilege;
  import?: Privilege;
  smartview?: Privilege;
  smartviewFolder?: Privilege;
};

export type Privilege = 'none' | 'view' | 'edit' | 'create' | 'delete';

export type Scope =
  | 'own'
  | 'team'
  | 'all'
  | 'settings'
  | 'analytics'
  | 'invite'
  | 'import'
  | 'contract'
  | 'smartview'
  | 'smartviewFolder';

export type Subject = 'project' | 'task' | 'form' | 'file' | 'team' | 'workspace' | 'contract';

export type WithPrivileges =
  | {
      // non-graphql fetches
      privileges: Privilege[];
    }
  | {
      // graphql fetches
      privilegeAll: Privilege | string;
      privilegeOwn: Privilege | string;
      privilegeTeam?: Privilege | string;
    };

export type ContactDTO = {
  id: number;
  name: string;
  title: string;
  emails: string[];
  phones: string[];
  companyId: number;
  createdBy: UserFromApi;
  createdAt: string;
  updatedAt: string;
  projectId: number;
  project: ProjectFromProjectListApi;
};

export type CreateContactDto = {
  name: string;
  title?: string;
  emails: string[];
  phones: string[];
  relatedProjects: number[];
};

export type UpdateContactDto = Partial<CreateContactDto> & {
  portalEnabled?: boolean;
};

export const RecordTypeToDefaultSearchType = {
  [RecordType.ACCOUNT]: 'AccountActive',
  [RecordType.DEAL]: 'DealActive',
  [RecordType.PROJECT]: 'Active'
};

export enum AccountType {
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL'
}

export type CreateProjectDTO = Partial<{
  title: string;
  type: RecordType;
  description: string;
  address: string[];
  imageUrl: string;
  homeOwnerEmail: string;
  workflowId: number;
  ahj: string;
  phone: string;
  additional: AdditionalDTO;
  membersList: { memberId: number; memberType: MemberType }[];
  isTemplate: boolean;
  stageId: number;
  blueprintId: number;
  contacts: { contactId: number }[];
  parentProjectId: number;
  dealValue: number;
  accountType: AccountType;
  ownerId: number;
  salesRepId: number;
  projectManagerId: number;
}>;

export type PropertyValueDTO =
  | null // empty value
  | string // TEXT, BOOLEAN, DATE, single DROPDOWN
  | number // NUMERIC
  | boolean // BOOLEAN (actually tracked as text value)
  | string[] // multiple DROPDOWN
  | Date // DATE (actually string as it will be converted to JSON anyway)
  | { id: number } // PERSON
  | number[]; // FILE

export type AdditionalDTO = { [columnId: number]: PropertyValueDTO | null };

export type SendSmsDTO =
  | {
      text: string;
      fromCompanyPhoneId: number;
      toCompanyPhoneId: number;
    }
  | {
      text: string;
      fromCompanyPhoneId: number;
      toContactId: number;
      toPhone: string;
    }
  | {
      text: string;
      fromCompanyPhoneId: number;
      toUserId: number;
    };

export type SendEmailDTO = {
  to: { name: string; email: string }[];
  cc: string[];
  bcc: string[];
  subject: string;
  body: string;
  attachments: any[];
  sendAt: 'now' | Date;
  replyToMessageId?: number | null;
};

export type SendBulkEmailDto = {
  subject: string;
  body: string;
  cc: string[];
  bcc: string[];
  accountId: number;
  targetProjectIds: number[];
  toPrimaryContactEmailOnly: boolean;
};

export type SendBulkSmsDto = {
  text: string;
  fromCompanyPhoneId: number;
  targetProjectIds: number[];
  toPrimaryContactPhoneOnly: boolean;
};

export type CreateReminderDTO = {
  assigneeId: number;
  title: string;
  dueDate: string;
  isCompleted: boolean;
  type: string;
  description: string;
  isAppointment?: boolean;
};

export type UpdateReminderDTO = Partial<CreateReminderDTO>;

export enum ProjectTrade {
  solar = 'Solar',
  storage = 'Storage',
  generator = 'Generator',
  ev_charger = 'EV Charger',
  roofing = 'Roofing',
  hvac = 'HVAC',
  electrical = 'Electrical',
  windows = 'Windows',
  general_contracting = 'General Contracting',
  home_improvement = 'Home Improvement'
}

export type EmailMessage = {
  id: number;
  createdBy: UserFromApi;
  receivedAt: Date;
  nylasMessageId: string;
  nylasThreadId: string;
  emailAccountId: number;
  originProjectId: number;
  from: string;
  to: string[];
  cc: string[];
  bcc: string[];
  subject: string;
  body: string;
  bodyCleaned: string;
  attachments: File[];
  isOutbox: boolean;
  isScheduled: boolean;
};

export enum AccountStatus {
  LEAD = 'LEAD',
  PROSPECT = 'PROSPECT',
  CUSTOMER = 'CUSTOMER'
}

export enum NylasAccountStatus {
  running = 'RUNNING',
  stopped = 'STOPPED',
  invalid = 'INVALID',
  connected = 'CONNECTED',
  sync_error = 'SYNC_ERROR'
}

export enum VirtualPropertyType {
  taskStartDate = 'taskStartDate',
  taskEndDate = 'taskEndDate',
  taskDescription = 'taskDescription',
  taskStatus = 'taskStatus'
}

export type CreateDashboardDTO = Pick<Dashboard, 'description' | 'name' | 'layout' | 'filterFolderId'> & {
  sharedRoles: number[];
  sharedTeams: number[];
  sharedUsers: number[];
};

export type UpdateDashboardDto = Partial<CreateDashboardDTO>;

export type SmartViewShownProperties = {
  [recordType in RecordType]: {
    [viewType in ProjectViewType]: number[];
  };
};

export type InviteUserDto = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  roleId: number;
  isCollaborator: boolean;
};

export type InvitationStatus = {
  email: string;
  status: 'success' | 'exists' | 'failed' | 'processing';
};

export type UpdateUserDto = Omit<InviteUserDto, 'isCollaborator'>;

export enum EntityAccessType {
  team = 'team',
  user = 'user',
  role = 'role'
}

export { EntityAccessType as ColumnAccessType };

export type ColumnAccess = {
  id: number;
  columnId: number;
  type: EntityAccessType;
  teamId?: number;
  roleId?: number;
};

export type AccessOption = { key: string } & (
  | {
      type: EntityAccessType.team;
      teamId: number;
      team: TeamGeneratedType & { users: UserFromApi[] };
    }
  | {
      type: EntityAccessType.user;
      userId?: number;
      user: UserFromApi;
    }
  | {
      type: EntityAccessType.role;
      roleId: number;
      role: RoleDTO;
    }
);

export type UpdateTeamDTO = {
  name: string;
  workers: number[];
};

export enum PropertySharingType {
  family = 'family'
}

export type ProjectAdditional = { [columnId: number]: PropertyValue };

export type FormLayoutDTO = Pick<FormLayout, 'separator' | 'columnId' | 'position'>;

export type CreateFormColumnDto = Pick<
  ProjectProperty,
  'type' | 'name' | 'isRequired' | 'isReadOnly' | 'additional' | 'multiple' | 'projectColumnId' | 'position'
> & { type: ExtraFormPropertyType };

export type PlayModeStorage = {
  recordType: RecordType;
  companyId: number;
  pointer: number;
  list: number[];
};

export type BulkUpdateTaskDto = {
  action: BulkTaskUpdateAction;
  value?: number;
  ids: number[];
  stageId?: number;
  startDate?: string;
  endDate?: string;
  assignees?: number[];
  replaceOldValue?: boolean;
};

export enum CatalogType {
  PRODUCT = 'PRODUCT',
  SERVICE = 'SERVICE'
}

export type CatalogItemCreateDto = {
  type: CatalogType;
  categoryId: number;
  manufacturer?: string;
  name: string;
  sku?: string;
  code?: string;
  datasheet?: string;
  description?: string;
  cost: number;
  price: number;
  spec: any;
  imageId?: number;
};

export type FileCommentCreateDto = {
  comment: string;
  x?: number;
  y?: number;
  isPinned?: boolean;
};

export type FileCommentUpdateDto = {
  comment?: string;
  x?: number;
  y?: number;
  isPinned?: boolean;
};

export type CommentCreateDto = {
  comment: string;
  type: CommentType;
  fileId?: number;
  taskId?: number;
  projectId?: number;
  parentId?: number;
  scope?: string;
  fileIds?: number[];
  systemId?: number;
};

export type CommentUpdateDto = {
  comment: string;
  fileIds?: number[];
};

export type CommentPinDto = {
  isPinned: boolean;
};

export type CatalogItemUpdateDto = {
  categoryId?: number;
  manufacturer?: string;
  name?: string;
  sku?: string;
  code?: string;
  datasheet?: string;
  description?: string;
  cost?: number;
  price?: number;
  spec?: {};
  imageId?: number;
  isArchived: boolean;
};

export type CatalogItemFromMasterDto = {
  masterItemId: number;
  cost: number;
  price: number;
  spec: {};
  imageId?: number;
  description?: string;
};

export type LineItemDto = {
  catalogItemId: number;
  quantity: number;
  unitCost: number;
  unitPrice: number;
  description?: string;
};
export const getProductDefaultValues = () => ({
  type: CatalogType.PRODUCT,
  manufacturer: faker.company.companyName(),
  name: faker.commerce.productName(),
  sku: faker.helpers.arrayElement(['units', 'kg', 'kw']),
  code: faker.datatype.uuid(),
  datasheet: faker.datatype.uuid(),
  description: faker.commerce.productDescription(),
  cost: faker.commerce.price(),
  price: faker.commerce.price(),
  isCustom: faker.helpers.arrayElement([false, true]),
  isEnabled: true
});

export const getServiceDefaultValues = () => ({
  category: faker.helpers.arrayElement(['Poopa', 'Loopa']),
  name: faker.commerce.productName(),
  code: faker.datatype.uuid(),
  datasheet: faker.datatype.uuid(),
  manufacturer: faker.company.companyName(),
  sku: faker.helpers.arrayElement(['units', 'kg', 'kw']),
  description: faker.commerce.productDescription(),
  cost: faker.commerce.price(),
  price: faker.commerce.price(),
  isCustom: faker.helpers.arrayElement([false, true]),
  isEnabled: true
});

export enum ImportMatchFoundStrategy {
  Create = 'REPLACE',
  Skip = 'SKIP',
  Update = 'ENRICH'
}

export enum ImportMatchNotFoundStrategy {
  Create = 'CREATE',
  Skip = 'SKIP'
}

export type StartImportPayload = {
  type: 'PROJECT' | 'DEAL';
  matchFoundStrategy: ImportMatchFoundStrategy;
  matchNotFoundStrategy: ImportMatchNotFoundStrategy;
  groupByColumnId: number;
  matchByColumnId: number;
  importData: any; // result of OneSchema import
};

export type IntegrationDTO = {
  provider: IntegrationProvider;
};

export type EnphaseIntegrationDTO = IntegrationDTO & {
  provider: IntegrationProvider.Enphase | IntegrationProvider.Enfake;
  email: string;
  password: string;
  clientId: string;
  clientSecret: string;
  apiKey: string;
};

export type SolarEdgeIntegrationDTO = IntegrationDTO & {
  provider: IntegrationProvider.SolarEdge | IntegrationProvider.LunarEdge;
  apiKey: string;
};

export enum BudgetPeriod {
  month = 'month'
}

export type IntegrationProgress = {
  inProgress: boolean;
  current: number;
  total: number;
};

export type InsertManualGeoDTO = {
  userId: number;
  deviceId: string;
  latitude: number;
  longitude: number;
  taskId: number;
  timestamp: string;
  buildRouteTo: boolean;
  runDummyFrom: boolean;
  runDummyInstant: boolean;
};
